import React from "react";
import { Layout } from "../components/containers/Layout";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { ContactForm } from "../components/Form/ContactForm";
import { SpecialTitle } from "../components/utility";
import { PrivacySection } from "../components/utility/quote/PrivacySection";
import { navigate } from "gatsby-link";
import Seo from "../components/marketing/Seo";
import { About_Meta } from "../data/MetaData";

export default function Contact() {
  const subscribeMC = async (values) => {
    const result = await addToMailchimp(values.email, {
      FNAME: values.fullname,
      COMNAME: values.companyname,
      PHONE: values.phonenumber,
      PINCODE: values.postcode,
      PRONAME: values.message,
    });
    console.log(result);
  };

  const sendMail = (values) => {
    console.log(values.email);
    try {
      const response = fetch("/.netlify/functions/sendgrid", {
        method: "POST",
        body: JSON.stringify({
          email: values.email,
          subject: "Digital Comparison",
          name: values.fullname,
        }),
      });
      if (!response.ok) {
        //not 200 response
        return;
      }
    } catch (e) {
      //error
      console.log(e);
    }
    console.log("Email sent.");
  };

  return (
    <Layout>
      <Seo title={About_Meta.title} description={About_Meta.description} />
      <section className="form-section-contact">
        <SpecialTitle>Please fill your inquiry in bellow form.</SpecialTitle>
        <ContactForm
          onSubmit={(values, actions) => {
            console.log(values);
            const timeOut = setTimeout(() => {
              actions.setSubmitting(false);
              clearTimeout(timeOut);
            }, 1000);
            sendMail(values);
            navigate("/");
            subscribeMC(values);
          }}
        />
        <PrivacySection />
      </section>
    </Layout>
  );
}
