export const About_Meta = {
  title: "Compare Business Telecommunications Services Providers in Melbourne",
  description:
    "Looking for Business Telecommunications Services in Melbourne from leading providers? We help organizations to find the right products for their business to meet their needs.",
};

export const Contact_Meta = {
  title: "Compare Business Telecommunications Services Providers in Melbourne",
  description:
    "Looking for Business Telecommunications Services in Melbourne from leading providers? We help organizations to find the right products for their business to meet their needs.",
};

export const Home_Meta = {
  title: "Compare Office IT Equipments Melbourne from Top Providers",
  description:
    "Compare and get free quotes about IT Solutions and Equipments for your business in Melbourne. Get free quotes on Business Phone Systems, IT hardware, Cyber Security etc in Melbourne from leading providers.",
};

export const Phone_Meta = {
  title: "Compare Business Phone System in Melbourne",
  description:
    "Compare business phone system from leading providers in Melbourne, Australia. We help you choose the right phone systems for your business. Get free quotes now!",
};

export const Internet_Meta = {
  title: "Compare Business Internet Service Melbourne from Top Providers",
  description:
    "Choose business internet services from top providers in Melbourne, Australia and get free quotes. Compare the best internet plans from market prices and choose the right one as per your needs.",
};

export const Solution_Meta = {
  title: "Compare IT Solutions Melbourne from Leading Providers",
  description:
    "Do you need Business IT Solutions in Melbourne, Australia? Contact Digital Comparison to get free quotes from leading Business IT Solution providers.",
};

export const Energy_Meta = {
  title: "Business Energy Comparisons Services Providers Melbourne, Australia",
  description:
    "Want to save the electricity at your workplace in Melbourne? Get Free business energy comparison at Digital Comparison and decide which are the best business energy plans provider in Australia.",
};
export const Hardware_Meta = {
  title: "Compare IT Hardware Services Melbourne",
  description:
    "Compare the prices of IT Hardware Support Services from leading providers at Digital Comparison and find the best market price and products including monitors, hard disk drives etc.",
};

export const Cyber_Meta = {
  title: "Compare Cyber Security Services Melbourne",
  description:
    "Compare Cyber Security Services from Top Providers in Melbourne, Australia and keep your business safe from hackers, scammers, and phishers with efficient IT cyber security solutions.",
};
export const Navigation_Meta = {
  title:
    "Compare Business Navigation System Melbourne | Navigation System Providers Australia",
  description:
    "Do You Need a Navigation System in Melbourne, Australia? Compare and get free quotes about viable navigation system that enables your business to be more profitable at Digital Comparison.",
};

export const Print_Meta = {
  title: "Compare Copiers & Printers Melbourne",
  description:
    "Compare Copiers & Printers Services price at Digital Comparison in Melbourne, Australia. Keep your staff happy and fulfill your client's requirement with the help of best copier and printers.",
};

export const Solar_Meta = {
  title: "Compare Business Solar Services Melbourne",
  description:
    "Compare the best business solar deals from leading providers across Melbourne, Australia. Get free quotes now and save your operating costs!",
};
export const Digital_Meta = {
  title:
    "Compare Digital Marketing Services in Melbourne from Leading Providers",
  description:
    "Are you looking for Digital Web Marketing agency in Melbourne? Compare Digital Marketing Services from top providers for the growth of your business and get free quotes.",
};
